.card {
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
}

.card-img {
  transition: transform 0.3s ease-in-out;
  max-height: 400px;
  object-fit: cover;
}

.card-img:hover {
  transform: scale(1.05);
 
}

.card-body {
  padding: 8px; /* Reduce el espacio interno del CardBody */
  padding-top: 12px;
  background: #f8f9fa;
}

.card-open {
  background-color: #ebe990 !important; /* Verde suave para tickets abiertos */
}

.card-closed {
  background-color: #f8f9fa !important; /* Gris claro para tickets cerrados */
}

.card-canceled {
  background-color: #fdbbaa !important; /* Gris claro para tickets cerrados */
  color: #333;
}

.cardTitle {
  font-size: 20px !important;
  font-weight: bold;
  margin: 0;
  color: #333;
  text-align: center;
  margin: 0; /* Quita margen superior e inferior */
  line-height: 1.2; /* Reduce la altura entre líneas */
}

.card-subtitle {
  margin-top: 4px; /* Ajusta el espacio superior */
  margin-bottom: 0; /* Quita el margen inferior */
  font-size: 0.9rem; /* Opcional: reduce el tamaño del subtítulo */
}

.card-text {
  margin-top: 2px; /* Reduce el espacio entre subtítulos y texto */
  margin-bottom: 0; /* Evita espacio adicional */
  font-size: 0.85rem; /* Opcional: ajusta el tamaño del texto */
}

.card .card-category {
  font-size: 16px !important;
  margin: 0;
  font-weight: 600;
  color: #333;
  text-align: center;
  padding-bottom: 0 !important;
}

.card-car-details {
  font-size: 12px !important;
  margin: 0;
  color: #333;
  text-align: center;
  padding-bottom: 0 !important;
}

.card-footer {
  background-color: #f8f9fa; /* Fondo gris claro */
  color: #6c757d; /* Texto gris oscuro */
  padding: 8px; /* Espaciado interno */
  font-size: 0.7rem; /* Tamaño de fuente más pequeño */
  text-transform: uppercase; /* Texto en mayúsculas */
  text-align: center; /* Centrar el texto */
  border-top: 1px solid #dee2e6; /* Borde superior */
}

/* Contenedor de la imagen */
.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

/* Indicador flotante */
.status-indicator {
  position: absolute;
  top: 8px; /* Ajusta según el diseño */
  left: 8px; /* Ajusta según el diseño */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 1);
  border-width: 1px;
  border-color: white;
  border-style: solid;
}

/* Colores dinámicos para cada estado */
.status-open {
  background-color: #28a745; /* Verde para abierto */
 
}

.status-closed {
  background-color: #6c757d; /* Gris para cerrado */
}

.status-canceled {
  background-color: #dc3545; /* Rojo para cancelado */
}


.references {
  display: flex;
  align-items: center;
  gap: 16px; /* Espaciado entre referencias */
}

.reference-item {
  display: flex;
  align-items: center;
}

.reference-bullet {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.bullet-open {
  background-color: #28a745; /* Verde */
}

.bullet-closed {
  background-color: #6c757d; /* Gris */
}

.bullet-canceled {
  background-color: #dc3545; /* Rojo */
}


